import { createContext } from 'react'
import { TablesState } from './Tables.types'

const initialState: TablesState = {
  series: [],
  authors: [],
  publishers: [],
  publications: [],
  updateSeries: () => Promise.resolve(),
  updateAuthors: () => Promise.resolve(),
  updatePublishers: () => Promise.resolve(),
  updatePublications: () => Promise.resolve(),
}

const TablesContext = createContext<TablesState>(initialState)

export default TablesContext
