export const currentYear = (): number => {
  return new Date().getFullYear()
}

export const clone = (object: any): any => JSON.parse(JSON.stringify(object))

export const createPath = (text: string): string =>
  normalizeText(text).replace(/\s/g, '-').replace(/[\.,]/g, '')

export const capitalizeFirst = (text: string) =>
  `${text.substring(0, 1).toUpperCase()}${text.substring(1).toLowerCase()}`

export const normalizeText = (text: string): string =>
  text
    .toLowerCase()
    .trim()
    .replace(/[áâäàåæã]/gi, 'a')
    .replace(/[éêëè]/gi, 'e')
    .replace(/[íïîì]/gi, 'i')
    .replace(/[óôöòœõ]/gi, 'o')
    .replace(/[úüûùµ]/gi, 'u')
    .replace(/ñ/gi, 'n')
    .replace(/ç/gi, 'c')
    .replace(/[ýÿ]/gi, 'y')
    .replace(/š/gi, 's')
    .replace(/ƒ/gi, 'f')
    .replace(/ð/gi, 'd')
    .replace(/[^a-z0-9\s\.,]/g, '')
    .replace(/ +(?= )/g, '')

export const shuffleArray = <T>(originalArray: T[]): T[] => {
  let array = JSON.parse(JSON.stringify(originalArray))
  for (let i = array.length - 1; i >= 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

export const sortByKeyExist = <T>(key: keyof T, a: T, b: T) => {
  if (a[key] && !b[key]) return -1
  if (!a[key] && b[key]) return 1
  return 0
}

export const authorName = (
  fullname: string | null,
  pseudonym: string | null
) => {
  if (fullname && pseudonym) return `${pseudonym}, ${fullname}`
  if (fullname) return fullname
  if (pseudonym) return pseudonym
  return '<N/A>'
}
