import {
  City,
  Genre,
  Status,
  DemAge,
  DemSex,
  Country,
  Department,
  SeriesGenre,
  Distribution,
  SeriesMinified,
  AuthorMinified,
  PublicationTeam,
  PublicationType,
  PublisherMinified,
  PublicationsMinified,
  AgeRanges,
} from '../../services/types'

export const UPDATE_TYPES = 'UPDATE_TYPES'
export const UPDATE_GENRES = 'UPDATE_GENRES'
export const UPDATE_CITIES = 'UPDATE_CITIES'
export const UPDATE_STATUS = 'UPDATE_STATUS'
export const UPDATE_DEM_AGE = 'UPDATE_DEM_AGE'
export const UPDATE_DEM_SEX = 'UPDATE_DEM_SEX'
export const UPDATE_COUNTRIES = 'UPDATE_COUNTRIES'
export const UPDATE_AGE_RANGES = 'UPDATE_AGE_RANGES'
export const UPDATE_DEPARMENTS = 'UPDATE_DEPARMENTS'
export const UPDATE_DISTRIBUTIONS = 'UPDATE_DISTRIBUTIONS'

export const UPDATE_AUX_TEAM = 'UPDATE_AUX_TEAM'
export const UPDATE_AUX_GENRE = 'UPDATE_AUX_GENRE'

export const UPDATE_SERIES = 'UPDATE_SERIES'
export const UPDATE_AUTHORS = 'UPDATE_AUTHORS'
export const UPDATE_PUBLISHERS = 'UPDATE_PUBLISHERS'
export const UPDATE_PUBLICATIONS = 'UPDATE_PUBLICATIONS'

export type RegisterState = {
  cities: City[]
  genres: Genre[]
  demAges: DemAge[]
  demSexes: DemSex[]
  statuses: Status[]
  countries: Country[]
  ageRanges: AgeRanges[]
  types: PublicationType[]
  departments: Department[]
  distributions: Distribution[]
  auxGenres: SeriesGenre[]
  auxTeam: PublicationTeam[]
  series: SeriesMinified[]
  authors: AuthorMinified[]
  publishers: PublisherMinified[]
  publications: PublicationsMinified[]
  updateTypes: () => Promise<void>
  updateCities: () => Promise<void>
  updateGenres: () => Promise<void>
  updateDemAges: () => Promise<void>
  updateDemSexes: () => Promise<void>
  updateStatuses: () => Promise<void>
  updateAgeRanges: () => Promise<void>
  updateCountries: () => Promise<void>
  updateDepartments: () => Promise<void>
  updateDistributions: () => Promise<void>
  updateAuxTeam: () => Promise<void>
  updateAuxGenre: () => Promise<void>
  updateSeries: () => Promise<void>
  updateAuthors: () => Promise<void>
  updatePublishers: () => Promise<void>
  updatePublications: () => Promise<void>
}

export type RegisterAction =
  | {
      type: typeof UPDATE_CITIES
      payload: City[]
    }
  | {
      type: typeof UPDATE_DEPARMENTS
      payload: Department[]
    }
  | {
      type: typeof UPDATE_COUNTRIES
      payload: Country[]
    }
  | {
      type: typeof UPDATE_AUTHORS
      payload: AuthorMinified[]
    }
  | {
      type: typeof UPDATE_PUBLISHERS
      payload: PublisherMinified[]
    }
  | {
      type: typeof UPDATE_SERIES
      payload: SeriesMinified[]
    }
  | {
      type: typeof UPDATE_PUBLICATIONS
      payload: PublicationsMinified[]
    }
  | {
      type: typeof UPDATE_AUX_GENRE
      payload: SeriesGenre[]
    }
  | {
      type: typeof UPDATE_AUX_TEAM
      payload: PublicationTeam[]
    }
  | {
      type: typeof UPDATE_DISTRIBUTIONS
      payload: Distribution[]
    }
  | {
      type: typeof UPDATE_TYPES
      payload: PublicationType[]
    }
  | {
      type: typeof UPDATE_STATUS
      payload: Status[]
    }
  | {
      type: typeof UPDATE_DEM_AGE
      payload: DemAge[]
    }
  | {
      type: typeof UPDATE_DEM_SEX
      payload: DemSex[]
    }
  | {
      type: typeof UPDATE_GENRES
      payload: Genre[]
    }
  | {
      type: typeof UPDATE_AGE_RANGES
      payload: AgeRanges[]
    }
