import React, { ReactNode, useReducer } from 'react'
import PropTypes from 'prop-types'

import ModalContext from './Modal.context'
import ModalReducer from './Modal.reducer'
import { ModalState, OPEN_MODAL, CLOSE_MODAL } from './Modal.types'

interface Props {
  children?: React.ReactNode
}

const ModalProvider: React.FC<Props> = ({ children }) => {
  let initialState: ModalState = {
    modal: null,
    isOpen: false,
    unclosable: false,
    openModal: () => null,
    closeModal: () => null,
  }

  const [state, dispatch] = useReducer(ModalReducer, initialState)

  const openModal = (modal: ReactNode, unclosable?: boolean) => {
    dispatch({ type: OPEN_MODAL, payload: modal, unclosable })
  }

  const closeModal = async () => {
    dispatch({ type: CLOSE_MODAL })
  }

  return (
    <ModalContext.Provider
      value={{
        isOpen: state.isOpen,
        modal: state.modal,
        unclosable: state.unclosable,
        openModal,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

ModalProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export default ModalProvider
