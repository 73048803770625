import axios from 'axios'
import {
  City,
  Status,
  DemAge,
  DemSex,
  Country,
  Department,
  SeriesGenre,
  Distribution,
  SeriesMinified,
  RegisterAuthor,
  AuthorMinified,
  PublicationType,
  PublicationTeam,
  PublisherMinified,
  RegisterPublisher,
  PublicationsMinified,
  RegisterSeries,
  Genre,
  AgeRanges,
  RegisterSofa2024,
  Author,
  Publisher,
  Series,
  Publications,
} from './types'

const baseUrl = 'https://registro.comiccolombiano.com/api/'

// POST Images
export const uploadImage = async (data: FormData) =>
  axios.post(`${baseUrl}image`, data)

// GET Params
export const getCities = async (): Promise<City[]> =>
  axios.get(`${baseUrl}cities`).then((res) => res.data.result)

export const getDepartments = async (): Promise<Department[]> =>
  axios.get(`${baseUrl}departments`).then((res) => res.data.result)

export const getCountries = async (): Promise<Country[]> =>
  axios.get(`${baseUrl}countries`).then((res) => res.data.result)

export const getDemAge = async (): Promise<DemAge[]> =>
  axios.get(`${baseUrl}demages`).then((res) => res.data.result)

export const getDemSex = async (): Promise<DemSex[]> =>
  axios.get(`${baseUrl}demsexes`).then((res) => res.data.result)

export const getStatuses = async (): Promise<Status[]> =>
  axios.get(`${baseUrl}statuses`).then((res) => res.data.result)

export const getDistributions = async (): Promise<Distribution[]> =>
  axios.get(`${baseUrl}distributions`).then((res) => res.data.result)

export const getPublicationTypes = async (): Promise<PublicationType[]> =>
  axios.get(`${baseUrl}types`).then((res) => res.data.result)

export const getGenres = async (): Promise<Genre[]> =>
  axios.get(`${baseUrl}genres`).then((res) => res.data.result)

export const getAgeRanges = async (): Promise<AgeRanges[]> =>
  axios.get(`${baseUrl}age_ranges`).then((res) => res.data.result)

// GETs Aux Tables
export const getPublicationTeam = async (): Promise<PublicationTeam[]> =>
  axios.get(`${baseUrl}comicTeam`).then((res) => res.data.result)

export const getSeriesGenre = async (): Promise<SeriesGenre[]> =>
  axios.get(`${baseUrl}comicGenre`).then((res) => res.data.result)

// GET Entities
export const getAuthors = async (): Promise<AuthorMinified[]> =>
  axios.get(`${baseUrl}authors`).then((res) => res.data.result)

export const getPublishers = async (): Promise<PublisherMinified[]> =>
  axios.get(`${baseUrl}publishers`).then((res) => res.data.result)

export const getSeries = async (): Promise<SeriesMinified[]> =>
  axios.get(`${baseUrl}series`).then((res) => res.data.result)

export const getPublications = async (): Promise<PublicationsMinified[]> =>
  axios.get(`${baseUrl}publications`).then((res) => res.data.result)

export const getAuthorsExt = async (): Promise<Author[]> =>
  axios.get(`${baseUrl}authors?extended=true`).then((res) => res.data.result)

export const getPublishersExt = async (): Promise<Publisher[]> =>
  axios.get(`${baseUrl}publishers?extended=true`).then((res) => res.data.result)

export const getSeriesExt = async (): Promise<Series[]> =>
  axios.get(`${baseUrl}series?extended=true`).then((res) => res.data.result)

export const getPublicationsExt = async (): Promise<Publications[]> =>
  axios.get(`${baseUrl}publications?extended=true`).then((res) => res.data.result)

// POSTs
export const postAuthor = async (body: RegisterAuthor) =>
  axios.post(`${baseUrl}author`, body).then((res) => res.data)

export const postPublisher = async (body: RegisterPublisher) =>
  axios.post(`${baseUrl}publisher`, body).then((res) => res.data)

export const postSeries = async (body: RegisterSeries) =>
  axios.post(`${baseUrl}series`, body).then((res) => res.data)

export const postChapter = async (body: RegisterSeries) =>
  axios.post(`${baseUrl}chapter`, body).then((res) => res.data)

export const postSofa = async (body: RegisterSofa2024) =>
  axios.post(`${baseUrl}sofa`, body).then((res) => res.data)
