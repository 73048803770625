exports.components = {
  "component---src-components-templates-author-template-index-tsx": () => import("./../../../src/components/templates/AuthorTemplate/index.tsx" /* webpackChunkName: "component---src-components-templates-author-template-index-tsx" */),
  "component---src-components-templates-comic-template-index-tsx": () => import("./../../../src/components/templates/ComicTemplate/index.tsx" /* webpackChunkName: "component---src-components-templates-comic-template-index-tsx" */),
  "component---src-components-templates-genre-template-index-tsx": () => import("./../../../src/components/templates/GenreTemplate/index.tsx" /* webpackChunkName: "component---src-components-templates-genre-template-index-tsx" */),
  "component---src-components-templates-publisher-template-index-tsx": () => import("./../../../src/components/templates/PublisherTemplate/index.tsx" /* webpackChunkName: "component---src-components-templates-publisher-template-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-academicos-tsx": () => import("./../../../src/pages/academicos.tsx" /* webpackChunkName: "component---src-pages-academicos-tsx" */),
  "component---src-pages-ayudar-tsx": () => import("./../../../src/pages/ayudar.tsx" /* webpackChunkName: "component---src-pages-ayudar-tsx" */),
  "component---src-pages-comic-tsx": () => import("./../../../src/pages/comic.tsx" /* webpackChunkName: "component---src-pages-comic-tsx" */),
  "component---src-pages-design-tsx": () => import("./../../../src/pages/design.tsx" /* webpackChunkName: "component---src-pages-design-tsx" */),
  "component---src-pages-editoriales-tsx": () => import("./../../../src/pages/editoriales.tsx" /* webpackChunkName: "component---src-pages-editoriales-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lineadetiempo-tsx": () => import("./../../../src/pages/lineadetiempo.tsx" /* webpackChunkName: "component---src-pages-lineadetiempo-tsx" */),
  "component---src-pages-personas-tsx": () => import("./../../../src/pages/personas.tsx" /* webpackChunkName: "component---src-pages-personas-tsx" */),
  "component---src-pages-registro-tsx": () => import("./../../../src/pages/registro.tsx" /* webpackChunkName: "component---src-pages-registro-tsx" */),
  "component---src-pages-viewer-20435-tsx": () => import("./../../../src/pages/viewer-20435.tsx" /* webpackChunkName: "component---src-pages-viewer-20435-tsx" */)
}

