import {
  TablesState,
  TablesAction,
  UPDATE_SERIES,
  UPDATE_AUTHORS,
  UPDATE_PUBLISHERS,
  UPDATE_PUBLICATIONS,
} from './Tables.types'

export default (
  state: TablesState,
  action: TablesAction
): TablesState => {
  switch (action.type) {
    case UPDATE_AUTHORS:
      return {
        ...state,
        authors: action.payload,
      }
    case UPDATE_PUBLISHERS:
      return {
        ...state,
        publishers: action.payload,
      }
    case UPDATE_SERIES:
      return {
        ...state,
        series: action.payload,
      }
    case UPDATE_PUBLICATIONS:
      return {
        ...state,
        publications: action.payload,
      }
    default:
      return state
  }
}
