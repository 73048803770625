import {
  Series,
  Author,
  Publisher,
  Publications,
} from '../../services/types'

export const UPDATE_SERIES = 'UPDATE_SERIES'
export const UPDATE_AUTHORS = 'UPDATE_AUTHORS'
export const UPDATE_PUBLISHERS = 'UPDATE_PUBLISHERS'
export const UPDATE_PUBLICATIONS = 'UPDATE_PUBLICATIONS'

export type TablesState = {
  series: Series[]
  authors: Author[]
  publishers: Publisher[]
  publications: Publications[]
  updateSeries: () => Promise<void>
  updateAuthors: () => Promise<void>
  updatePublishers: () => Promise<void>
  updatePublications: () => Promise<void>
}

export type TablesAction =
  | {
      type: typeof UPDATE_AUTHORS
      payload: Author[]
    }
  | {
      type: typeof UPDATE_PUBLISHERS
      payload: Publisher[]
    }
  | {
      type: typeof UPDATE_SERIES
      payload: Series[]
    }
  | {
      type: typeof UPDATE_PUBLICATIONS
      payload: Publications[]
    }
