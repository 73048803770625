import {
  RegisterState,
  RegisterAction,
  UPDATE_TYPES,
  UPDATE_GENRES,
  UPDATE_CITIES,
  UPDATE_STATUS,
  UPDATE_DEM_SEX,
  UPDATE_DEM_AGE,
  UPDATE_COUNTRIES,
  UPDATE_DEPARMENTS,
  UPDATE_DISTRIBUTIONS,
  UPDATE_AUX_TEAM,
  UPDATE_AUX_GENRE,
  UPDATE_SERIES,
  UPDATE_AUTHORS,
  UPDATE_PUBLISHERS,
  UPDATE_PUBLICATIONS,
  UPDATE_AGE_RANGES,
} from './Register.types'

export default (
  state: RegisterState,
  action: RegisterAction
): RegisterState => {
  switch (action.type) {
    case UPDATE_CITIES:
      return {
        ...state,
        cities: action.payload,
      }
    case UPDATE_DEPARMENTS:
      return {
        ...state,
        departments: action.payload,
      }
    case UPDATE_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      }
    case UPDATE_AUTHORS:
      return {
        ...state,
        authors: action.payload,
      }
    case UPDATE_PUBLISHERS:
      return {
        ...state,
        publishers: action.payload,
      }
    case UPDATE_SERIES:
      return {
        ...state,
        series: action.payload,
      }
    case UPDATE_PUBLICATIONS:
      return {
        ...state,
        publications: action.payload,
      }
    case UPDATE_AUX_GENRE:
      return {
        ...state,
        auxGenres: action.payload,
      }
    case UPDATE_AUX_TEAM:
      return {
        ...state,
        auxTeam: action.payload,
      }
    case UPDATE_TYPES:
      return {
        ...state,
        types: action.payload,
      }
    case UPDATE_DISTRIBUTIONS:
      return {
        ...state,
        distributions: action.payload,
      }
    case UPDATE_STATUS:
      return {
        ...state,
        statuses: action.payload,
      }
    case UPDATE_DEM_SEX:
      return {
        ...state,
        demSexes: action.payload,
      }
    case UPDATE_DEM_AGE:
      return {
        ...state,
        demAges: action.payload,
      }
    case UPDATE_GENRES:
      return {
        ...state,
        genres: action.payload,
      }
    case UPDATE_AGE_RANGES:
      return {
        ...state,
        ageRanges: action.payload,
      }
    default:
      return state
  }
}
