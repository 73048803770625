import { ModalState, ModalAction, OPEN_MODAL, CLOSE_MODAL } from './Modal.types'

export default (state: ModalState, action: ModalAction): ModalState => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        isOpen: true,
        modal: action.payload,
        unclosable: action.unclosable ?? false,
      }
    case CLOSE_MODAL:
      return {
        ...state,
        isOpen: false,
        modal: null,
      }
    default:
      return state
  }
}
