import { createContext } from 'react'
import { RegisterState } from './Register.types'

const initialState: RegisterState = {
  types: [],
  cities: [],
  genres: [],
  demAges: [],
  statuses: [],
  demSexes: [],
  ageRanges: [],
  countries: [],
  departments: [],
  distributions: [],
  auxTeam: [],
  auxGenres: [],
  series: [],
  authors: [],
  publishers: [],
  publications: [],
  updateTypes: () => Promise.resolve(),
  updateGenres: () => Promise.resolve(),
  updateCities: () => Promise.resolve(),
  updateDemAges: () => Promise.resolve(),
  updateDemSexes: () => Promise.resolve(),
  updateStatuses: () => Promise.resolve(),
  updateAgeRanges: () => Promise.resolve(),
  updateCountries: () => Promise.resolve(),
  updateDepartments: () => Promise.resolve(),
  updateDistributions: () => Promise.resolve(),
  updateAuxTeam: () => Promise.resolve(),
  updateAuxGenre: () => Promise.resolve(),
  updateSeries: () => Promise.resolve(),
  updateAuthors: () => Promise.resolve(),
  updatePublishers: () => Promise.resolve(),
  updatePublications: () => Promise.resolve(),
}

const RegisterContext = createContext<RegisterState>(initialState)

export default RegisterContext
